<template>
  <!-- Main Header -->
  <header class="main-header">
    <!-- Header Top -->
    <div class="header-top">
      <div class="auto-container">
        <div class="inner clearfix">
          <div class="top-left">
            <ul class="social-links clearfix">
              <li class="social-title">Follow Us:</li>
              <li>
                <a href="https://www.facebook.com/profile.php?id=100094575695159"><span class="fab fa-facebook-f"></span></a>
              </li>
              <li>
                <a href="#"><span class="fab fa-twitter"></span></a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/african-foundation-for-envrironment-and-climate-change"><span class="fab fa-linkedin-in"></span></a>
              </li>
              <!-- <li>
                <a href="#"><span class="fab fa-pinterest-p"></span></a>
              </li> -->
            </ul>
          </div>

          <div class="top-right">
            <ul class="info clearfix">
              <li class="search-btn">
                <button type="button" class="theme-btn search-toggler">
                  <span class="fa fa-search"></span>
                </button>
              </li>
              <li>
                <a href="tel:++2348035854295"
                  ><span class="icon fa fa-phone-alt"></span> Call: &nbsp;+234 803 585 4295</a
                >
              </li>
              <li>
                <a href="mailto:info@afrclimate.org"
                  ><span class="icon fa fa-envelope"></span> Email:
                  &nbsp;info@afrclimate.org</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Header Upper -->
    <div class="header-upper">
      <div class="auto-container">
        <div class="inner-container clearfix">
          <!--Logo-->
          <div class="logo-box">
            <div class="logo">
              <a
                href="/"
                title="AFECC - Humanitarian NGO"
                ><img
                  src="images/logo.png"
                  alt="LoveUs - Charity and Fundraising HTML Template"
                  title="LoveUs - Charity and Fundraising HTML Template"
              /></a>
            </div>
          </div>

          <!--Nav Box-->
          <div class="nav-outer clearfix">
            <!--Mobile Navigation Toggler-->
            <div class="mobile-nav-toggler ">
              <span class="icon flaticon-menu-1"></span>
            </div>

            <!-- Main Menu -->
            <nav class="main-menu navbar-expand-md navbar-light">
              <div
                class="collapse navbar-collapse show clearfix"
                id="navbarSupportedContent"
              >
                <ul class="navigation clearfix">

                    <li><a href="/">Home</a></li>

                  <!-- <li class="current dropdown">
                    <a href="/">Home</a>
                    <ul>
                      <li><a href="/">Home page 01</a></li>
                      <li><a href="index-2.html">Home page 02</a></li>
                      <li><a href="index-3.html">Home page 03</a></li>
                      <li><a href="index-4.html">Home page 04</a></li>
                      <li><a href="index-5.html">Home page 05</a></li>
                      <li><a href="index-6.html">Home page 06</a></li>
                      <li><a href="index-6.html">Home page 06</a></li>
                      <li><a href="index-7.html">Home page 07</a></li>
                      <li><a href="index-8.html">Home page 08</a></li>
                      <li class="dropdown">
                        <a href="/">Header Styles</a>
                        <ul>
                          <li><a href="/">Header Style One</a></li>
                          <li><a href="index-2.html">Header Style Two</a></li>
                          <li>
                            <a href="index-3.html">Header Style Three</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li> -->

                  <li class="dropdown">
                    <a href="/about">About</a>
                    <ul>
                      <li><a href="/about">About Us</a></li>
                      <li><a href="/services">Our Services</a></li>
                      <li><a href="/volunteers">Our Volunteers</a></li>
                      <li><a href="/faq">Faq's</a></li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <a href="/causes">Causes</a>
                    <ul>
                      <li><a href="/causes">Causes</a></li>
                      <li><a href="cause-single.html">Cause Details</a></li>
                    </ul>
                  </li>
                  <!-- <li class="dropdown">
                    <a href="/donate">Pages</a>
                    <ul>
                      <li><a href="/events">Events</a></li>
                      <li><a href="event-single.html">Event Details</a></li>
                      <li><a href="portfolio.html">Portfolio</a></li>
                      <li class="dropdown">
                        <a href="shop.html">Shop</a>
                        <ul>
                          <li><a href="shop.html">Products</a></li>
                          <li>
                            <a href="product-details.html">Product Details</a>
                          </li>
                          <li>
                            <a href="shopping-cart.html">Shopping Cart</a>
                          </li>
                          <li><a href="checkout.html">Checkout</a></li>
                        </ul>
                      </li>
                      <li><a href="donate.html">Make Donation</a></li>
                    </ul>
                  </li> -->
                  <li class="dropdown">
                    <a href="/blog">Blog</a>
                    <ul>
                      <li><a href="/blog">Our Blog</a></li>
                      <li><a href="blog-single.html">Blog Single</a></li>
                    </ul>
                  </li>
                  <li><a href="/contact">Contact</a></li>
                </ul>
              </div>
            </nav>
            <!-- Main Menu End-->

            <div class="link-box clearfix">
              <div class="donate-link">
                <a href="/donate" class="theme-btn btn-style-one"
                  ><span class="btn-title">Donate Now</span></a
                >
              </div>
              <!-- <div class="cart-link">
                <a href="shopping-cart.html" class="theme-btn"
                  ><span class="icon flaticon-paper-bag"></span
                ></a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Header Upper-->

    <!-- Sticky Header  -->
    <div class="sticky-header">
      <div class="auto-container clearfix">
        <!--Logo-->
        <div class="logo pull-left">
          <a href="/" title=""
            ><img src="images/sticky-logo.png" alt="" title=""
          /></a>
        </div>
        <!--Right Col-->
        <div class="pull-right">
          <!-- Main Menu -->
          <nav class="main-menu clearfix">
            <!--Keep This Empty / Menu will come through Javascript-->
          </nav>
          <!-- Main Menu End-->
        </div>
      </div>
    </div>
    <!-- End Sticky Menu -->

    <!-- Mobile Menu  -->
    <div class="mobile-menu">
      <div class="menu-backdrop"></div>
      <div class="close-btn"><span class="icon flaticon-cancel"></span></div>

      <nav class="menu-box">
        <div class="nav-logo">
          <a href="/"><img src="images/logo.png" alt="" title="" /></a>
        </div>
        <div class="menu-outer">
          <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->
        </div>
        <!--Social Links-->
        <div class="social-links">
          <ul class="clearfix">
            <li>
              <a href="#"><span class="fab fa-twitter"></span></a>
            </li>
            <li>
              <a href="#"><span class="fab fa-facebook-square"></span></a>
            </li>
            <li>
              <a href="#"><span class="fab fa-pinterest-p"></span></a>
            </li>
            <li>
              <a href="#"><span class="fab fa-instagram"></span></a>
            </li>
            <li>
              <a href="#"><span class="fab fa-youtube"></span></a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <!-- End Mobile Menu -->
  </header>
  <!-- End Main Header -->
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      $(".mobile-menu .menu-box").mCustomScrollbar();

      var mobileMenuContent = $(".main-header .nav-outer .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);
      $(".sticky-header .main-menu").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.dropdown .dropdown-btn").on("click", function () {
        $(this).toggleClass("open");
        $(this).prev("ul").slideToggle(500);
      });
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".mobile-menu .menu-backdrop,.mobile-menu .close-btn").on(
        "click",
        function () {
          $("body").removeClass("mobile-menu-visible");
        }
      );
    }
  },
};
</script>