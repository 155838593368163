<template>
  <div>
    <!-- Banner Section -->
    <section class="banner-section">
      <div
        class="banner-carousel love-carousel owl-theme owl-carousel"
        data-options='{"loop": true, "margin": 0, "autoheight":true, "lazyload":true, "nav": true, "dots": true, "autoplay": true, "autoplayTimeout": 6000, "smartSpeed": 300, "responsive":{ "0" :{ "items": "1" }, "768" :{ "items" : "1" } , "1000":{ "items" : "1" }}}'
      >
        <!-- Slide Item -->
        <div class="slide-item">
          <div
            class="image-layer lazy-image"
            data-bg="url('images/main-slider/1.jpg')"
          ></div>

          <div class="auto-container">
            <div class="content-box">
              <h2>You Can Help <br />The Poor</h2>
              <div class="text">
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.
              </div>
              <div class="btn-box">
                <a href="donate.html" class="theme-btn btn-style-one"
                  ><span class="btn-title">Donate Now</span></a
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Slide Item -->
        <div class="slide-item">
          <div
            class="image-layer lazy-image"
            data-bg="url('images/main-slider/2.jpg')"
          ></div>

          <div class="auto-container">
            <div class="content-box">
              <h2>You Can Help <br />The Poor</h2>
              <div class="text">
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.
              </div>
              <div class="btn-box">
                <a href="donate.html" class="theme-btn btn-style-one"
                  ><span class="btn-title">Donate Now</span></a
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Slide Item -->
        <div class="slide-item">
          <div
            class="image-layer lazy-image"
            data-bg="url('images/main-slider/3.jpg')"
          ></div>

          <div class="auto-container">
            <div class="content-box">
              <h2>You Can Help <br />The Poor</h2>
              <div class="text">
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.
              </div>
              <div class="btn-box">
                <a href="donate.html" class="theme-btn btn-style-one"
                  ><span class="btn-title">Donate Now</span></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--End Banner Section -->

    <!--About Section-->
    <section class="about-section">
      <div class="top-rotten-curve"></div>
      <div class="bottom-rotten-curve"></div>
      <div class="circle-one"></div>
      <div class="circle-two"></div>

      <div class="auto-container">
        <div class="row clearfix">
          <!--Left Column-->
          <div class="left-column col-lg-6 col-md-12 col-sm-12">
            <div class="inner">
              <div class="sec-title">
                <!-- <div class="sub-title">About Us</div> -->
                <h2>Fighting Climate Change</h2>
                <div class="text">
                  Fighting the adverse effects of Climate Change is one of our
                  core mission. We are also committed to protecting and
                  preserving our Earth, Keepig our envionment protected from
                  waste, dangerous chemicals and ensuring our continued
                  habitation.
                </div>
                <div class="link-box clearfix">
                  <a href="/causes" class="theme-btn btn-style-one"
                    ><span class="btn-title">Read More</span></a
                  >
                </div>
              </div>
            </div>
          </div>
          <!--Right Column-->
          <div class="right-column col-lg-6 col-md-12 col-sm-12">
            <div class="inner">
              <div class="row clearfix">
                <!--About Feature-->
                <div class="about-feature col-md-6 col-sm-12">
                  <div class="inner-box wow fadeInUp">
                    <div class="icon-box">
                      <span class="flaticon-user"></span>
                    </div>
                    <h4>Become Volunteer</h4>
                    <a href="#" class="over-link"></a>
                  </div>
                </div>
                <!--About Feature-->
                <div class="about-feature col-md-6 col-sm-12">
                  <div class="inner-box wow fadeInUp" data-wow-delay="300ms">
                    <div class="icon-box">
                      <span class="flaticon-heart-2"></span>
                    </div>
                    <h4>Quick Fundraise</h4>
                    <a href="#" class="over-link"></a>
                  </div>
                </div>
                <!--About Feature-->
                <div class="about-feature col-md-6 col-sm-12">
                  <div class="inner-box wow fadeInUp">
                    <div class="icon-box">
                      <span class="flaticon-coin-2"></span>
                    </div>
                    <h4>Give Donation</h4>
                    <a href="#" class="over-link"></a>
                  </div>
                </div>
                <!--About Feature-->
                <div class="about-feature col-md-6 col-sm-12">
                  <div class="inner-box wow fadeInUp" data-wow-delay="300ms">
                    <div class="icon-box">
                      <span class="flaticon-care"></span>
                    </div>
                    <h4>Become Volunteer</h4>
                    <a href="#" class="over-link"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--Causes Section-->
    <section class="causes-section">
      <div class="auto-container">
        <div class="sec-title centered">
          <div class="sub-title">Our Causes</div>
          <h2>Popular Causes</h2>
          <!-- <div class="text">Cupidatat non proident sunt</div> -->
        </div>

        <div class="row clearfix">
          <!--Cause Block-->
          <div class="cause-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInUp" data-wow-delay="300ms">
              <div class="image-box">
                <figure class="image">
                  <a href="cause-single.html"
                    ><img
                      class="lazy-image"
                      src="images/afecc/desert.jpg"
                      data-src="images/afecc/desert.jpg"
                      alt=""
                  /></a>
                </figure>
              </div>
              <div class="donate-info">
                <div class="progress-box">
                  <div class="bar">
                    <div class="bar-inner count-bar" data-percent="70%">
                      <div class="count-text">70%</div>
                    </div>
                  </div>
                </div>
                <div class="donation-count clearfix">
                  <span class="raised"><strong>Raised:</strong> $6,000</span>
                  <span class="goal"><strong>Goal:</strong> $8,000</span>
                </div>
              </div>
              <div class="lower-content">
                <h3><a href="cause-single">Climate Change </a></h3>
                <div class="text">
                  We work to raise awareness about climate change and advocate
                  for policies and actions that reduce greenhouse gas emissions.
                  We also support initiatives for renewable energy, energy
                  efficiency, and carbon neutrality.
                </div>
                <div class="link-box">
                  <a href="cause-single.html" class="theme-btn btn-style-two"
                    ><span class="btn-title">Read More</span></a
                  >
                </div>
              </div>
            </div>
          </div>

          <!--Cause Block-->
          <div class="cause-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInUp" data-wow-delay="300ms">
              <div class="image-box">
                <figure class="image">
                  <a href="/cause-single">
                    <!-- <img class="lazy-image" src="images/resource/image-spacer-for-validation.png" data-src="images/resource/cause-image-2.jpg" alt=""> -->
                    <img
                      class="lazy-image"
                      src="images/afecc/community.jpg"
                      data-src="images/resource/cause-image-2.jpg"
                      alt=""
                    />
                  </a>
                </figure>
              </div>
              <div class="donate-info">
                <div class="progress-box">
                  <div class="bar">
                    <div class="bar-inner count-bar" data-percent="70%">
                      <div class="count-text">70%</div>
                    </div>
                  </div>
                </div>
                <div class="donation-count clearfix">
                  <span class="raised"><strong>Raised:</strong> $6,000</span>
                  <span class="goal"><strong>Goal:</strong> $8,000</span>
                </div>
              </div>
              <div class="lower-content">
                <h3>
                  <a href="cause-single.html"
                    >Community <br />Outreach Programs</a
                  >
                </h3>
                <div class="text">
                  These programs aim to address various environmental issues,
                  promote community development, and improve the overall
                  well-being of community members. We often involve
                  collaboration between community heads, community members,
                  local businesses, other non-profit organizations, and
                  government agencies.
                </div>
                <div class="link-box">
                  <a href="/cause-single" class="theme-btn btn-style-two"
                    ><span class="btn-title">Read More</span></a
                  >
                </div>
              </div>
            </div>
          </div>

          <!--Cause Block-->
          <div class="cause-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInUp" data-wow-delay="600ms">
              <div class="image-box">
                <figure class="image">
                  <a href="/cause-single"
                    ><img
                      class="lazy-image"
                      src="images/afecc/training.jpeg"
                      data-src="images/afecc/training.jpeg"
                      alt=""
                  /></a>
                </figure>
              </div>
              <div class="donate-info">
                <div class="progress-box">
                  <div class="bar">
                    <div class="bar-inner count-bar" data-percent="70%">
                      <div class="count-text">70%</div>
                    </div>
                  </div>
                </div>
                <div class="donation-count clearfix">
                  <span class="raised"><strong>Raised:</strong> $6,000</span>
                  <span class="goal"><strong>Goal:</strong> $8,000</span>
                </div>
              </div>
              <div class="lower-content">
                <h3>
                  <a href="cause-single.html"
                    >Training <br />Environmentalists</a
                  >
                </h3>
                <div class="text">
                  We engage in training the next generation of
                  environmentalists. It is crucial for building a strong and
                  effective movement that can address pressing environmental
                  issues. They are taught Campaign advocacy skills, research and
                  data analysis, community engagements etc
                </div>
                <div class="link-box">
                  <a href="/cause-single" class="theme-btn btn-style-two"
                    ><span class="btn-title">Read More</span></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--Video Section-->
    <section class="video-section">
      <div class="circle-one"></div>
      <div class="circle-two"></div>
      <div class="top-rotten-curve"></div>
      <div class="bottom-rotten-curve"></div>

      <!--Image Layer-->
      <div class="image-layer wow slideInLeft" data-wow-delay="500ms">
        <div
          class="bg-image lazy-image"
          data-bg="url('images/background/fluid-image-1.jpg')"
        ></div>
      </div>
      <div class="auto-container">
        <div class="row clearfix">
          <!--Text Column-->
          <div class="text-column col-lg-6 col-md-12 col-sm-12">
            <div class="inner">
              <div class="sec-title">
                <div class="sub-title">Watch Video</div>
                <h2>Watch Our Latest Activities</h2>
                <div class="text">
                  We do so much for the community and the envionment. Check out
                  our video detailing our activities
                </div>
                <div class="link-box clearfix">
                  <a href="/donate" class="theme-btn btn-style-one"
                    ><span class="btn-title">Donate Now</span></a
                  >
                </div>
              </div>
            </div>
          </div>
          <!--Image Column-->
          <div class="image-column col-lg-6 col-md-12 col-sm-12">
            <div class="inner wow fadeInLeft" data-wow-delay="0ms">
              <figure class="image-box">
                <img
                  class="lazy-image"
                  src="images/resource/image-spacer-for-validation.png"
                  data-src="images/resource/video-image-1.jpg"
                  alt=""
                />
                <a
                  href="https://www.youtube.com/watch?v=C9rVAbcQH_U"
                  class="lightbox-image over-link"
                  ><span class="icon flaticon-play-button"></span
                ></a>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--What We Do Section-->
    <section class="what-we-do">
      <div class="auto-container">
        <div class="sec-title centered">
          <div class="sub-title">What We Do?</div>
          <h2>We’re on a Mission to Solve <br />The Problems</h2>
          <!-- <div class="text">The environment evangelists</div> -->
        </div>

        <div class="row clearfix">
          <!--Service Block-->
          <div class="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="inner-box">
              <div class="icon-box">
                <span class="flaticon-water-bottle"></span>
              </div>
              <h3>Water Conservation</h3>
              <div class="text">
                We work to protect and conserve freshwater resources, raise
                awareness about water scarcity, and promote sustainable water
                management practices. They may advocate for the protection of
                rivers, lakes, and wetlands, and work towards providing clean
                and safe drinking water for all.
              </div>
            </div>
          </div>

          <!--Service Block-->
          <div class="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="inner-box">
              <div class="icon-box"><span class="flaticon-fruit"></span></div>
              <h3>Waste Disposal & Recycling</h3>
              <div class="text">
                We solve waste disposal educating people on the channels of
                proper waste segragation, recycling,compositing and landfill
                management.
              </div>
            </div>
          </div>

          <!--Service Block-->
          <div class="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="inner-box">
              <div class="icon-box">
                <span class="flaticon-medicine"></span>
              </div>
              <h3>Renewable Energy</h3>
              <div class="text">
                Renewable Energy for environmental sustainability and
                biodiversity maintenance. Offering Various Green options such as
                Solar, Wind etc for use in Homes and Industrial cases.
              </div>
            </div>
          </div>

          <!--Service Block-->
          <div class="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="inner-box">
              <div class="icon-box"><span class="flaticon-reading"></span></div>
              <h3>Environmental Education and Awareness</h3>
              <div class="text">
                We play a crucial role in educating the public about
                environmental issues and promoting sustainable lifestyles. They
                may organize workshops, campaigns, and events to raise awareness
                and encourage individuals and communities to take action.
              </div>
            </div>
          </div>
        </div>

        <div class="bottom-image">
          <img
            class="lazy-image"
            src="images/resource/image-spacer-for-validation.png"
            data-src="images/resource/services-bottom-image.png"
            alt=""
          />
        </div>
      </div>
    </section>

    <!--Mission Vision Section-->
    <section class="mission-vision">
      <div class="circle-one"></div>
      <div class="circle-two"></div>

      <div class="auto-container">
        <div class="mission">
          <div class="row clearfix">
            <div class="text-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner">
                <div class="sec-title">
                  <!-- <div class="sub-title">Proident sunt</div> -->
                  <h2>Our Mission</h2>
                  <div class="text">
                    ...Protecting and Preserving our environment.
                  </div>
                  <div class="link-box">
                    <a href="/causes" class="theme-btn btn-style-one"
                      ><span class="btn-title">Read More</span></a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="image-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner">
                <div class="row clearfix">
                  <div class="image wow fadeInUp" data-wow-delay="0ms">
                    <img
                      class="lazy-image"
                      src="/images/afecc/wastes.jpg"
                      data-src="/images/afecc/wastes.jpg"
                      width="640px"
                      height="480px"
                      alt=""
                    />
                  </div>
                  <div class="image wow fadeInDown" data-wow-delay="0ms">
                    <img
                      class="lazy-image"
                      src="/images/afecc/heap.jpg"
                      data-src="/images/afecc/heap.jpg"
                      width="640px"
                      height="480px"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vision">
          <div class="row clearfix">
            <div class="text-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner">
                <div class="sec-title">
                  <!-- <div class="sub-title">Proident sunt</div> -->
                  <h2>Our Vision</h2>
                  <div class="text">
                    A future whrere our environmental resources are used
                    sustainably and recycled adequately.
                  </div>
                  <div class="link-box">
                    <a href="/causes" class="theme-btn btn-style-one"
                      ><span class="btn-title">Read More</span></a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="image-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner">
                <div class="image wow fadeInLeft" data-wow-delay="0ms">
                  <img
                    class="lazy-image"
                    src="images/afecc/trees.jpg"
                    data-src="images/resource/vision-image-1.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--Call To Action Section-->
    <section class="call-to-action">
      <!--Image Layer-->
      <div
        class="image-layer lazy-image"
        data-bg="url('images/background/call-to-action-1.jpg')"
      ></div>

      <div class="auto-container">
        <div class="inner">
          <div class="sec-title centered">
            <h2>How Can You Help?</h2>
            <div class="text">
              Your donation will help us save and improve lives with research,
              education and emergency care.
            </div>
            <div class="link-box clearfix">
              <a href="causes.html" class="theme-btn btn-style-three"
                ><span class="btn-title">Donate Now</span></a
              ><a href="donate" class="theme-btn btn-style-one"
                ><span class="btn-title">Join Us Now</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--Team Section-->
    <section class="team-section">
      <div class="bottom-rotten-curve"></div>

      <div class="auto-container">
        <div class="sec-title centered">
          <div class="sub-title">Meet Our Team</div>
          <h2>Our Amazing Members</h2>
          <!-- <div class="text">Cupidatat non proident sunt</div> -->
        </div>

        <div class="row clearfix">
          <!--Team Block-->
          <div class="team-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInUp" data-wow-delay="0ms">
              <figure class="image-box">
                <a href="#"
                  ><img
                    class="lazy-image"
                    src="images/afecc/ceo.jpeg"
                    data-src="images/resource/team-image-1.jpg"
                    alt=""
                /></a>
              </figure>
              <div class="lower-box">
                <div class="content">
                  <h3><a href="#">Lawal Y. Gada</a></h3>
                  <div class="designation">Executive Director</div>
                  <div class="social-links">
                    <ul class="clearfix">
                      <li>
                        <a href="#"><span class="fab fa-facebook-f"></span></a>
                      </li>
                      <li>
                        <a href="#"><span class="fab fa-twitter"></span></a>
                      </li>
                      <li>
                        <a href="#"><span class="fab fa-linkedin-in"></span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Team Block-->
          <div class="team-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInUp" data-wow-delay="300ms">
              <figure class="image-box">
                <a href="#"
                  ><img
                    class="lazy-image"
                    src="/images/afecc/project_manager.jpeg"
                    data-src="/images/afecc/project_manager.jpeg"
                    alt=""
                /></a>
              </figure>
              <div class="lower-box">
                <div class="content">
                  <h3><a href="#">Arch. Idris Illo</a></h3>
                  <div class="designation">Projects Manager</div>
                  <div class="social-links">
                    <ul class="clearfix">
                      <li>
                        <a href="#"><span class="fab fa-facebook-f"></span></a>
                      </li>
                      <li>
                        <a href="#"><span class="fab fa-twitter"></span></a>
                      </li>
                      <li>
                        <a href="#"><span class="fab fa-linkedin-in"></span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Team Block-->
          <div class="team-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInUp" data-wow-delay="600ms">
              <figure class="image-box">
                <a href="#"
                  ><img
                    class="lazy-image"
                    src="/images/afecc/admin_officer.jpeg"
                    data-src="/images/afecc/admin_officer.jpeg"
                    alt=""
                /></a>
              </figure>
              <div class="lower-box">
                <div class="content">
                  <h3><a href="#">Yusuf Mohammed</a></h3>
                  <div class="designation">Administrative Officer</div>
                  <div class="social-links">
                    <ul class="clearfix">
                      <li>
                        <a href="#"><span class="fab fa-facebook-f"></span></a>
                      </li>
                      <li>
                        <a href="#"><span class="fab fa-twitter"></span></a>
                      </li>
                      <li>
                        <a href="#"><span class="fab fa-linkedin-in"></span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--Upcoming Events Section-->
    <section class="upcoming-events">
      <div class="circle-one"></div>
      <div class="circle-two"></div>

      <div class="auto-container">
        <div class="sec-title centered">
          <div class="sub-title">Upcoming Events</div>
          <h2>Our Events</h2>
          <!-- <div class="text">Cupidatat non proident sunt</div> -->
        </div>

        <div class="carousel-box">
          <div
            class="single-item-carousel love-carousel owl-theme owl-carousel"
            data-options='{"loop": false, "margin": 0, "autoHeight":false, "singleItem" : true, "autoplay": true, "lazyload":true, "nav": true, "dots": true, "autoplay": true, "autoplayTimeout": 6000, "smartSpeed": 300, "responsive":{ "0" :{ "items": "1" }, "768" :{ "items" : "1" } , "1024":{ "items" : "1" }}}'
          >
            <!--Slide-->
            <div class="slide-item">
              <div class="event-block">
                <div class="inner-box clearfix">
                  <div class="image-column">
                    <div class="image-box">
                      <img
                        class="lazy-image owl-lazy"
                        src="images/afecc/heap.pjg"
                        data-src="images/resource/event-image-1.jpg"
                        alt=""
                      />
                    </div>
                    <div
                      class="bg-image-layer lazy-image"
                      data-bg="url('images/resource/event-image-1.jpg')"
                    ></div>
                    <a href="event-single.html" class="over-link"></a>
                  </div>
                  <div class="text-column">
                    <div class="inner">
                      <h3>
                        <a href="event-single.html"
                          >Donation is Hope <br />for Children</a
                        >
                      </h3>
                      <ul class="info clearfix">
                        <li>
                          <span class="icon far fa-clock"></span> 9.00 AM -
                          11.00 PM
                        </li>
                        <li>
                          <span class="icon fa fa-map-marker-alt"></span> 29
                          Newyork City
                        </li>
                      </ul>
                      <div class="text">
                        Commodo consequat. Duis aute irure dolor in repreh
                        enderit volupte velit ese cillum dolore fugiat nula
                        pariatur occaecat. cupidatat non proident sunt in culpa.
                      </div>
                      <div class="link-box">
                        <a
                          href="event-single.html"
                          class="theme-btn btn-style-one"
                          ><span class="btn-title">Get Ticket</span></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Slide-->
            <div class="slide-item">
              <div class="event-block">
                <div class="inner-box clearfix">
                  <div class="image-column">
                    <div class="image-box">
                      <img
                        class="lazy-image owl-lazy"
                        src="images/resource/image-spacer-for-validation.png"
                        data-src="images/resource/event-image-1.jpg"
                        alt=""
                      />
                    </div>
                    <div
                      class="bg-image-layer lazy-image"
                      data-bg="url('images/resource/event-image-1.jpg')"
                    ></div>
                    <a href="event-single.html" class="over-link"></a>
                  </div>
                  <div class="text-column">
                    <div class="inner">
                      <h3>
                        <a href="event-single.html"
                          >Donation is Hope <br />for Children</a
                        >
                      </h3>
                      <ul class="info clearfix">
                        <li>
                          <span class="icon far fa-clock"></span> 9.00 AM -
                          11.00 PM
                        </li>
                        <li>
                          <span class="icon fa fa-map-marker-alt"></span> 29
                          Newyork City
                        </li>
                      </ul>
                      <div class="text">
                        Commodo consequat. Duis aute irure dolor in repreh
                        enderit volupte velit ese cillum dolore fugiat nula
                        pariatur occaecat. cupidatat non proident sunt in culpa.
                      </div>
                      <div class="link-box">
                        <a
                          href="event-single.html"
                          class="theme-btn btn-style-one"
                          ><span class="btn-title">Get Ticket</span></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Slide-->
            <!--Slide-->
            <div class="slide-item">
              <div class="event-block">
                <div class="inner-box clearfix">
                  <div class="image-column">
                    <div class="image-box">
                      <img
                        class="lazy-image owl-lazy"
                        src="images/resource/image-spacer-for-validation.png"
                        data-src="images/resource/event-image-1.jpg"
                        alt=""
                      />
                    </div>
                    <div
                      class="bg-image-layer lazy-image"
                      data-bg="url('images/resource/event-image-1.jpg')"
                    ></div>
                    <a href="event-single.html" class="over-link"></a>
                  </div>
                  <div class="text-column">
                    <div class="inner">
                      <h3>
                        <a href="event-single.html"
                          >Donation is Hope <br />for Children</a
                        >
                      </h3>
                      <ul class="info clearfix">
                        <li>
                          <span class="icon far fa-clock"></span> 9.00 AM -
                          11.00 PM
                        </li>
                        <li>
                          <span class="icon fa fa-map-marker-alt"></span> 29
                          Newyork City
                        </li>
                      </ul>
                      <div class="text">
                        Commodo consequat. Duis aute irure dolor in repreh
                        enderit volupte velit ese cillum dolore fugiat nula
                        pariatur occaecat. cupidatat non proident sunt in culpa.
                      </div>
                      <div class="link-box">
                        <a
                          href="event-single.html"
                          class="theme-btn btn-style-one"
                          ><span class="btn-title">Get Ticket</span></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Funfacts Section -->
    <section class="facts-section">
      <div class="auto-container">
        <div class="inner-container">
          <!-- Fact Counter -->
          <div class="fact-counter">
            <div class="row clearfix">
              <!--Column-->
              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  class="inner wow fadeInLeft"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div class="content">
                    <div class="count-outer count-box">
                      <span class="count-text" data-speed="3000" data-stop="90"
                        >7</span
                      >
                    </div>
                    <div class="counter-title">Project Completed</div>
                  </div>
                </div>
              </div>

              <!--Column-->
              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  class="inner wow fadeInLeft"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  <div class="content">
                    <div class="count-outer count-box alternate">
                      <span class="count-text" data-speed="3000" data-stop="216"
                        >23</span
                      >
                    </div>
                    <div class="counter-title">Satisfied Clients</div>
                  </div>
                </div>
              </div>

              <!--Column-->
              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  class="inner wow fadeInLeft"
                  data-wow-delay="600ms"
                  data-wow-duration="1500ms"
                >
                  <div class="content">
                    <div class="count-outer count-box">
                      <span class="count-text" data-speed="2000" data-stop="35"
                        >9</span
                      >
                    </div>
                    <div class="counter-title">Experienced Staff</div>
                  </div>
                </div>
              </div>

              <!--Column-->
              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  class="inner wow fadeInLeft"
                  data-wow-delay="900ms"
                  data-wow-duration="1500ms"
                >
                  <div class="content">
                    <div class="count-outer count-box">
                      <span class="count-text" data-speed="2000" data-stop="15"
                        >4</span
                      >
                    </div>
                    <div class="counter-title">Awards Win</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Funfacts Section -->

    <!--News Section-->
    <section class="news-section">
      <div class="top-rotten-curve"></div>

      <div class="auto-container">
        <div class="title-box clearfix">
          <div class="sec-title">
            <div class="sub-title">Get In Touch</div>
            <h2>Latest Article</h2>
          </div>

          <div class="link">
            <a href="blog.html" class="theme-btn btn-style-one"
              ><span class="btn-title">All Articles</span></a
            >
          </div>
        </div>

        <div class="row clearfix">
          <!--News Block-->
          <div class="news-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInUp" data-wow-delay="0ms">
              <div class="image-box">
                <figure class="image">
                  <a href="blog-single.html"
                    ><img
                      class="lazy-image"
                      src="images/afecc/heap.jpg"
                      data-src="images/resource/blog-image-1.jpg"
                      alt=""
                  /></a>
                </figure>
              </div>
              <div class="lower-content">
                <div class="date">25 <span class="month">Aug</span></div>
                <h3>
                  <a href="blog-single.html"
                    >The Waste Disposal epidemic in our Society</a
                  >
                </h3>
                <div class="text">
                  Waste Heaps popularly known as Bola is ravaging our
                  communities...
                </div>
                <div class="post-meta">
                  <ul class="clearfix">
                    <li>
                      <a href="#"
                        ><span class="icon fa fa-user"></span> Admin</a
                      >
                    </li>
                    <li>
                      <a href="#"
                        ><span class="icon fa fa-comments"></span> 29
                        Comments</a
                      >
                    </li>
                    <li>
                      <a href="#"><span class="icon fa fa-share-alt"></span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!--News Block-->
          <div class="news-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInUp" data-wow-delay="300ms">
              <div class="image-box">
                <figure class="image">
                  <a href="blog-single"
                    ><img
                      class="lazy-image"
                      src="images/afecc/poverty.jpg"
                      data-src="images/resource/blog-image-2.jpg"
                      alt=""
                  /></a>
                </figure>
              </div>
              <div class="lower-content">
                <div class="date">25 <span class="month">Aug</span></div>
                <h3>
                  <a href="blog-single.html"
                    >How Poverty contributes to Climate Change</a
                  >
                </h3>
                <div class="text">
                  According to the World Bank, Sub-Saharan Africa
                  has the highest poverty rates globally, with..
                </div>
                <div class="post-meta">
                  <ul class="clearfix">
                    <li>
                      <a href="#"
                        ><span class="icon fa fa-user"></span> Admin</a
                      >
                    </li>
                    <li>
                      <a href="#"
                        ><span class="icon fa fa-comments"></span> 29
                        Comments</a
                      >
                    </li>
                    <li>
                      <a href="#"><span class="icon fa fa-share-alt"></span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!--News Block-->
          <div class="news-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInUp" data-wow-delay="600ms">
              <div class="image-box">
                <figure class="image">
                  <a href="blog-single.html"
                    ><img
                      class="lazy-image"
                      src="images/afecc/training.jpeg"
                      data-src="images/resource/blog-image-3.jpg"
                      alt=""
                  /></a>
                </figure>
              </div>
              <div class="lower-content">
                <div class="date">25 <span class="month">Aug</span></div>
                <h3>
                  <a href="blog-single.html"
                    >AFECC Trains Youth on
                    Climate Change Advocacy</a
                  >
                </h3>
                <div class="text">
                    Over 400 Nigerian Youth have been trained on 
                    Community Sensitization...
                </div>
                <div class="post-meta">
                  <ul class="clearfix">
                    <li>
                      <a href="#"
                        ><span class="icon fa fa-user"></span> Admin</a
                      >
                    </li>
                    <li>
                      <a href="#"
                        ><span class="icon fa fa-comments"></span> 29
                        Comments</a
                      >
                    </li>
                    <li>
                      <a href="#"><span class="icon fa fa-share-alt"></span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Insta Gallery Section -->
    <section class="insta-gallery">
      <div
        class="insta-gallery-carousel love-carousel owl-theme owl-carousel"
        data-options='{"loop": false, "margin": 0, "autoheight":true, "lazyload":true, "nav": true, "dots": true, "autoplay": true, "autoplayTimeout": 5000, "smartSpeed": 500, "responsive":{ "0" :{ "items": "1" },"600" :{ "items": "2" }, "768" :{ "items" : "3" }, "1024":{ "items" : "4" }, "1366":{ "items" : "4" }, "1500":{ "items" : "5" }, "1920":{ "items" : "6" }}}'
      >
        <!-- Gallery Item -->
        <div class="gallery-item">
          <div class="image-box">
            <figure class="image">
              <img
                class="lazy-image owl-lazy"
                src="images/resource/image-spacer-for-validation.png"
                data-src="images/gallery/1.jpg"
                alt=""
              />
            </figure>
            <div class="overlay-box">
              <a
                href="images/gallery/1.jpg"
                class="lightbox-image"
                data-fancybox="gallery"
                ><span class="icon flaticon-instagram"></span
              ></a>
            </div>
          </div>
        </div>

        <!-- Gallery Item -->
        <div class="gallery-item">
          <div class="image-box">
            <figure class="image">
              <img
                class="lazy-image owl-lazy"
                src="images/resource/image-spacer-for-validation.png"
                data-src="images/gallery/2.jpg"
                alt=""
              />
            </figure>
            <div class="overlay-box">
              <a
                href="images/gallery/2.jpg"
                class="lightbox-image"
                data-fancybox="gallery"
                ><span class="icon flaticon-instagram"></span
              ></a>
            </div>
          </div>
        </div>

        <!-- Gallery Item -->
        <div class="gallery-item">
          <div class="image-box">
            <figure class="image">
              <img
                class="lazy-image owl-lazy"
                src="images/resource/image-spacer-for-validation.png"
                data-src="images/gallery/3.jpg"
                alt=""
              />
            </figure>
            <div class="overlay-box">
              <a
                href="images/gallery/3.jpg"
                class="lightbox-image"
                data-fancybox="gallery"
                ><span class="icon flaticon-instagram"></span
              ></a>
            </div>
          </div>
        </div>

        <!-- Gallery Item -->
        <div class="gallery-item">
          <div class="image-box">
            <figure class="image">
              <img
                class="lazy-image owl-lazy"
                src="images/resource/image-spacer-for-validation.png"
                data-src="images/gallery/4.jpg"
                alt=""
              />
            </figure>
            <div class="overlay-box">
              <a
                href="images/gallery/4.jpg"
                class="lightbox-image"
                data-fancybox="gallery"
                ><span class="icon flaticon-instagram"></span
              ></a>
            </div>
          </div>
        </div>

        <!-- Gallery Item -->
        <div class="gallery-item">
          <div class="image-box">
            <figure class="image">
              <img
                class="lazy-image owl-lazy"
                src="images/resource/image-spacer-for-validation.png"
                data-src="images/gallery/5.jpg"
                alt=""
              />
            </figure>
            <div class="overlay-box">
              <a
                href="images/gallery/5.jpg"
                class="lightbox-image"
                data-fancybox="gallery"
                ><span class="icon flaticon-instagram"></span
              ></a>
            </div>
          </div>
        </div>

        <!-- Gallery Item -->
        <div class="gallery-item">
          <div class="image-box">
            <figure class="image">
              <img
                class="lazy-image owl-lazy"
                src="images/resource/image-spacer-for-validation.png"
                data-src="images/gallery/6.jpg"
                alt=""
              />
            </figure>
            <div class="overlay-box">
              <a
                href="images/gallery/6.jpg"
                class="lightbox-image"
                data-fancybox="gallery"
                ><span class="icon flaticon-instagram"></span
              ></a>
            </div>
          </div>
        </div>

        <!-- Gallery Item -->
        <div class="gallery-item">
          <div class="image-box">
            <figure class="image">
              <img
                class="lazy-image owl-lazy"
                src="images/resource/image-spacer-for-validation.png"
                data-src="images/gallery/1.jpg"
                alt=""
              />
            </figure>
            <div class="overlay-box">
              <a
                href="images/gallery/1.jpg"
                class="lightbox-image"
                data-fancybox="gallery"
                ><span class="icon flaticon-instagram"></span
              ></a>
            </div>
          </div>
        </div>

        <!-- Gallery Item -->
        <div class="gallery-item">
          <div class="image-box">
            <figure class="image">
              <img
                class="lazy-image owl-lazy"
                src="images/resource/image-spacer-for-validation.png"
                data-src="images/gallery/2.jpg"
                alt=""
              />
            </figure>
            <div class="overlay-box">
              <a
                href="images/gallery/2.jpg"
                class="lightbox-image"
                data-fancybox="gallery"
                ><span class="icon flaticon-instagram"></span
              ></a>
            </div>
          </div>
        </div>

        <!-- Gallery Item -->
        <div class="gallery-item">
          <div class="image-box">
            <figure class="image">
              <img
                class="lazy-image owl-lazy"
                src="images/resource/image-spacer-for-validation.png"
                data-src="images/gallery/3.jpg"
                alt=""
              />
            </figure>
            <div class="overlay-box">
              <a
                href="images/gallery/3.jpg"
                class="lightbox-image"
                data-fancybox="gallery"
                ><span class="icon flaticon-instagram"></span
              ></a>
            </div>
          </div>
        </div>

        <!-- Gallery Item -->
        <div class="gallery-item">
          <div class="image-box">
            <figure class="image">
              <img
                class="lazy-image owl-lazy"
                src="images/resource/image-spacer-for-validation.png"
                data-src="images/gallery/4.jpg"
                alt=""
              />
            </figure>
            <div class="overlay-box">
              <a
                href="images/gallery/4.jpg"
                class="lightbox-image"
                data-fancybox="gallery"
                ><span class="icon flaticon-instagram"></span
              ></a>
            </div>
          </div>
        </div>

        <!-- Gallery Item -->
        <div class="gallery-item">
          <div class="image-box">
            <figure class="image">
              <img
                class="lazy-image owl-lazy"
                src="images/resource/image-spacer-for-validation.png"
                data-src="images/gallery/5.jpg"
                alt=""
              />
            </figure>
            <div class="overlay-box">
              <a
                href="images/gallery/5.jpg"
                class="lightbox-image"
                data-fancybox="gallery"
                ><span class="icon flaticon-instagram"></span
              ></a>
            </div>
          </div>
        </div>

        <!-- Gallery Item -->
        <div class="gallery-item">
          <div class="image-box">
            <figure class="image">
              <img
                class="lazy-image owl-lazy"
                src="images/resource/image-spacer-for-validation.png"
                data-src="images/gallery/6.jpg"
                alt=""
              />
            </figure>
            <div class="overlay-box">
              <a
                href="images/gallery/6.jpg"
                class="lightbox-image"
                data-fancybox="gallery"
                ><span class="icon flaticon-instagram"></span
              ></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--End Gallery Section -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "HomeView",
  components: {
    // HelloWorld
  },
};
</script>
