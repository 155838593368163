<template>
  <div class="">
    <!-- Preloader -->
    <div class="preloader"><div class="icon"></div></div>
    
    <HeaderView/>

    <!--Search Popup-->
    <div id="search-popup" class="search-popup">
      <div class="close-search theme-btn">
        <span class="flaticon-cancel"></span>
      </div>
      <div class="popup-inner">
        <div class="overlay-layer"></div>
        <div class="search-form">
          <form method="post" action="/">
            <div class="form-group">
              <fieldset>
                <input
                  type="search"
                  class="form-control"
                  name="search-input"
                  value=""
                  placeholder="Search Here"
                  required
                />
                <input type="submit" value="Search Now!" class="theme-btn" />
              </fieldset>
            </div>
          </form>

          <br />
          <h3>Recent Search Keywords</h3>
          <ul class="recent-searches">
            <li><a href="#">Finance</a></li>
            <li><a href="#">Idea</a></li>
            <li><a href="#">Service</a></li>
            <li><a href="#">Growth</a></li>
            <li><a href="#">Plan</a></li>
          </ul>
        </div>
      </div>
    </div>

    <!-- End of Search Pop up -->
    <router-view />

    <FooterView />

  </div>
</template>



<script>
// @ is an alias to /src
import FooterView from "@/components/FooterView.vue";
import HeaderView from "@/components/HeaderView.vue";

export default {
  components: {
    HeaderView,
    FooterView,
  },
};
</script>

<style>
</style>
